import React, { useState } from 'react';

import BooleanQueryComponent from './BooleanQueryComponent';
import BooleanSearchNew from './BooleanSearchNew';

const BooleanSearch = ({
  setWordsToBeExcludedInSearchObject,
  setWordsToBeIncludedInSearchObject,
  wordsToBeIncludedInSearchObject,
  wordsToBeExcludedInSearchObject,
}) => {
  const [isItemsVisibleIncluded, setItemsVisibleIncluded] = useState(true);
  const [isItemsVisibleExcluded, setItemsVisibleExcluded] = useState(true);

  const [inputValueIncluded, setInputValueIncluded] = useState(
    wordsToBeIncludedInSearchObject,
  );
  const [inputValueExcluded, setInputValueExcluded] = useState(
    wordsToBeExcludedInSearchObject,
  );

  const toggleItemsVisibilityIncluded = () => {
    if (inputValueIncluded === '') {
      setItemsVisibleIncluded(!isItemsVisibleIncluded);
    }
  };

  console.log('inputValueIncluded', wordsToBeExcludedInSearchObject);
  console.log('inputValueIncluded', wordsToBeExcludedInSearchObject);

  const toggleItemsVisibilityExcluded = () => {
    if (inputValueExcluded === '') {
      setItemsVisibleExcluded(!isItemsVisibleExcluded);
    }
  };

  const handleInputChangeIncluded = (event) => {
    setWordsToBeIncludedInSearchObject(event.target.value);
    setInputValueIncluded(event.target.value);
    setItemsVisibleIncluded(false);
  };

  const convertOrStringToArray = (inputString) => {
    return inputString.split(/ OR | or | Or /i).map((item) => item.trim());
  };

  const handleInputChangeExcluded = (event) => {
    // const resultArray = convertOrStringToArray(newValue);

    // if (event.key === ' ') {
    //   event.preventDefault();
    //   const newValue = event.target.value + ' OR ';
    //   setInputValueIncluded(newValue);
    //   setWordsToBeIncludedInSearchObject(newValue);
    // }
    let resultArray = event.target.value;

    // const resultString = resultArray;
    const resultArrayNew = convertOrStringToArray(resultArray);
    const resultString = resultArrayNew.join(', ');
    setInputValueExcluded(resultArray);
    setWordsToBeExcludedInSearchObject(resultString);

    setItemsVisibleExcluded(false);
  };

  const handleSpaceKeyPress = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      const newValue = event.target.value + ' OR ';
      setInputValueExcluded(newValue);

      const resultArrayNew = convertOrStringToArray(newValue);
      const resultString = resultArrayNew.join(', ');

      setWordsToBeExcludedInSearchObject(resultString);
    }
  };
  const [activeTab, setActiveTab] = useState('included');

  const handleInputChangeIncludedCustom = (value) => {
    setWordsToBeIncludedInSearchObject(value);
    setInputValueIncluded(value);
    setItemsVisibleIncluded(false);
  };

  return (
    <section className=" ">
      <BooleanQueryComponent
        handleInputChangeIncludedCustom={handleInputChangeIncludedCustom}
      />

      <div className="w-full flex flex-col  items-start">
        <div className="keyword mr-6">
          <div className="title font-medium">Excluded keywords</div>
        </div>
        <div className="relative w-full mx-auto mt-1">
          <div className="flex space-x-4">
            <textarea
              onChange={handleInputChangeExcluded}
              // onBlur={validateQuery}
              onKeyDown={handleSpaceKeyPress}
              value={inputValueExcluded}
              placeholder="Exclude keywords"
              className="w-[70vw] h-20 p-2 border border-gray-300 rounded-md"
            />
            <textarea
              placeholder="Eg :( Nestle OR Kitkat ) "
              class="w-[30vw] h-20 p-2 border-none focus:border-none focus:ring-0  focus:outline-none  rounded-md"
              readonly=""
            ></textarea>
          </div>
        </div>

        {wordsToBeIncludedInSearchObject &&
          wordsToBeIncludedInSearchObject != '' && (
            <p>Included : {wordsToBeIncludedInSearchObject} </p>
          )}
        {wordsToBeExcludedInSearchObject &&
          wordsToBeExcludedInSearchObject != '' && (
            <p>Excluded : {wordsToBeExcludedInSearchObject} </p>
          )}
      </div>
    </section>
  );
};

export default BooleanSearch;
