import React, { createContext, useContext, useState } from 'react';

import ToastBody from './ToastBody';

const ToastContext = createContext();

export const ToastProviderUi = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = ({ message, type }) => {
    setToast({ message, type });
    setTimeout(() => {
      setToast(null);
    }, 3000); // Toast will disappear after 3 seconds
  };

  const dismissToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <ToastBody
          message={toast.message}
          type={toast.type}
          onClose={dismissToast}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
